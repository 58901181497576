import appTenantConstants from '@core/utils/constants/appTenant';
import loyaltyConstants from '@core/utils/constants/loyalty';
import purchaseFlowConstants from '@core/utils/constants/purchaseFlow';
import { getCurrent as appTenantUtilsGetCurrent } from '@core/utils/appTenantUtils';
import apiServiceTenant from '@core/api/apiServiceTenant';
import { normalizeTenantConfig } from '@core/data/response/normalizeTenantConfig';
import { isStoreOutDated } from '@core/utils/storeUtils';
import authenticationConstants from '@core/utils/constants/authentication.js';

const STORE_NAME = 'appTenant';

export default {
    name: STORE_NAME,
    namespaced: true,
    state: {
        name: appTenantConstants.NO_TENANT_NAME,
        path: appTenantConstants.NO_TENANT_NAME,
        loading: false,
        timestamp: 0,
        config: {
            authentication: {
                type: null
            },
            loyalty: {
                currencyType: loyaltyConstants.CURRENCY_TYPES.POINTS,
                type: loyaltyConstants.TYPES.BOTH,
                name: '',
                earnRate: null
            },
            purchaseFlow: {
                eventTicket: {
                    type: purchaseFlowConstants.TYPES.DIRECT
                }
            },
            ACLAllow: {
                guestCanPurchase: false
            },
            discounts: [],
            minimumRedemptionPoints: loyaltyConstants.REDEEM.MIN_POINTS,
            queueIt: false
        },
        imagePaths: { }
    },
    getters: {
        getLoyaltyProgramName: state => state.config.loyalty.name,
        getLoyaltyType: state => state.config.loyalty.type,
        getDiscounts: state => state.config.discounts,
        isTenant: state => state.name !== appTenantConstants.NO_TENANT_NAME,
        isLoyaltyTypeAllowEarn: state => state.config.loyalty.type === loyaltyConstants.TYPES.EARN || state.config.loyalty.type === loyaltyConstants.TYPES.BOTH,
        isLoyaltyTypeAllowRedeem: state => state.config.loyalty.type === loyaltyConstants.TYPES.REDEEM || state.config.loyalty.type === loyaltyConstants.TYPES.BOTH,
        isLoyaltyTypeNone: state => state.config.loyalty.type === loyaltyConstants.TYPES.NONE,
        isLoyaltyCurrencyTypePoints: state => state.config.loyalty.currencyType === loyaltyConstants.CURRENCY_TYPES.POINTS,
        isLoyaltyCurrencyTypeMoney: state => state.config.loyalty.currencyType === loyaltyConstants.CURRENCY_TYPES.MONEY,
        earnRate: state => state.config.loyalty.earnRate,
        imagePaths: state => state.imagePaths,
        purchaseFlowEventTicketType: state => state.config.purchaseFlow.eventTicket.type,
        isPurchaseFlowEventTicketTypeDirect: state => state.config.purchaseFlow.eventTicket.type === purchaseFlowConstants.TYPES.DIRECT,
        isPurchaseFlowEventTicketTypeBoth: state => state.config.purchaseFlow.eventTicket.type === purchaseFlowConstants.TYPES.BOTH,
        isPurchaseFlowEventTicketTypeProvider: state => state.config.purchaseFlow.eventTicket.type === purchaseFlowConstants.TYPES.PROVIDER,
        authenticationType: state => state.config.authentication.type,
        isAuthenticationTypeSSO: state => state.config.authentication.type === authenticationConstants.TYPES.SSO,
        ACLAllow: state => state.config.ACLAllow,
        hasAuthentication: state => !!state.config.authentication.type,
        minimumRedemptionPoints: state => state.config.minimumRedemptionPoints,
        hasQueueIt: state => state.config.queueIt
    },
    mutations: {
        setTenant( state, tenant ) {
            state.name = tenant;
            state.path = tenant;
        },
        setLoading( state, loading ) {
            state.loading = loading;
        },
        setTimestamp( state, timestamp ) {
            this.state.timestamp = timestamp;
        },
        /**
         * TODO
         *
         * 1.rename mutation. This is called by appTenantFactory to overwrite with what
         * tenant is configure
         * 2. ticketmaster should have the appTenant not from core
         *
         * @param {Object} state
         * @param {Object} config
         */
        setConfig( state, config ) {
            state.config = {
                ...state.config,
                loyalty: {
                    ...state.config.loyalty,
                    ...config.loyalty
                },
                purchaseFlow: {
                    ...state.config.purchaseFlow,
                },
                authentication: {
                    type: config.authentication && config.authentication.type || null
                },
                ACLAllow: {
                    ...state.config.ACLAllow,
                    ...config.ACLAllow,
                },
                queueIt: config.queueIt || false
            };
        },
        setPurchaseFlowEventTicketType( state, type ) {
            state.config.purchaseFlow.eventTicket.type = type;
        },
        setLoyaltyProgram( state, loyaltyProgramName ) {
            state.config.loyalty = {
                ...state.config.loyalty,
                name: loyaltyProgramName
            };
        },
        setLoyaltyType( state, type ) {
            state.config.loyalty = {
                ...state.config.loyalty,
                type,
            };
        },
        setAuthenticationType( state, authenticationType ) {
            state.config.authentication.type = authenticationType;
        },
        setImagePaths( state, imagePaths ) {
            state.imagePaths = imagePaths;
        },
        setDiscounts( state, discounts ) {
            state.config.discounts = discounts;
        },
        setMinimumRedemptionPoints( state, minimumRedemptionPoints ) {
            state.config.minimumRedemptionPoints = minimumRedemptionPoints;
        }
    },
    actions: {
        async read( { commit, state } ) {
            return await new Promise( resolve => {
                setTimeout( ( ) => {
                    commit( 'setTenant', appTenantUtilsGetCurrent( ) );
                    resolve( state.name );
                }, 100 );
            } );
        },
        async getConfig ( { commit, state } ) {
            if ( state.loading ) {
                return await new Promise( ( resolve ) => {
                    let unwatchLoading = this.watch(
                        ( state ) => {
                            return state[STORE_NAME].loading;
                        },
                        ( val ) => {
                            if ( !val ) {
                                unwatchLoading();
                                resolve();
                            }
                        }
                    );
                } );
            } else if( isStoreOutDated( state.timestamp ) ) {
                commit( 'setLoading', true );
                commit( 'setTimestamp', Date.now() );
                const api = await apiServiceTenant( '' );
                const { data, success } = await api.tenant.getConfig(  );

                if ( success ) {
                    const config = normalizeTenantConfig( data );

                    commit( 'setPurchaseFlowEventTicketType', config.transactableType );
                    commit( 'setAuthenticationType', config.authenticationType );
                    commit( 'setLoyaltyProgram', config.loyalty.programName );
                    commit( 'setLoyaltyType', config.loyalty.type );
                    commit( 'setDiscounts', config.discounts );
                    commit( 'setMinimumRedemptionPoints', config.minimumRedemptionPoints );
                }
                commit( 'setLoading', false );
            }
            return state.config;
        },
        /**
         * Get SSO Login from API. This is used for tenants that are using custom SSO
         *
         * @param context - object
         * @param urlSuccess -  string
         * @param urlFailure - string
         * @param locale - string
         * @returns {Promise<*|null>}
         */
        async getSSOLoginUrl( context, { urlSuccess, urlFailure, locale } ) {
            const api = await apiServiceTenant( '' );
            const { success, data } = await api.tenant.getSSOLoginUrl( { urlSuccess, urlFailure, locale } );

            if ( success ) {
                return data;
            } else {
                return null;
            }
        }
    }
};
